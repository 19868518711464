<template>
    <!-- tmc -->
    <div class="row">
        <div class="col-xl-6 ">
            <div class="form-group">
                <label>Pilihan Metode Konsultasi Dokter :</label>
                <div class="form-check">
                    
                    <label class="form-check-label pointer">
                        <input
                        class="form-check-input pointer"
                        type="radio"
                        :value="false"
                        v-model="telmed"
                        @change="change"
                    />
                        Datang Langsung (default)
                    </label>
                </div>
                <div class="form-check">
                    <label class="form-check-label pointer">
                        <input
                        class="form-check-input pointer"
                        type="radio"
                        :value="true"
                        v-model="telmed"
                        @change="change"
                    />Telekonsultasi
                    </label>
                </div>
            </div>
        </div>
    </div>
    <!-- tmc end -->
</template>

<script>
export default {
    data(){
        return {
            telmed: false
        }
    },
    props:{
        selectedDokter: Array,
        checkTeleconsult: Object
    },
    created() {
        this.$emit('teleconsult', false)
        if(this.checkTeleconsult && this.checkTeleconsult.reservasi) {
            if(this.checkTeleconsult.reservasi.teleconsultPayment) {
                this.telmed = true
            } else {
                this.telmed = false
            }
        } else {
            this.telmed = false
        }
        setTimeout(()=> {
            this.$emit('teleconsult', this.telmed)
        }, 500)
         
    },
    watch:{
        "selectedDokter.length"(newVal, oldVal){
            if(!newVal){
                this.telmed = false
                this.change()
            }
        }
    },
    methods: {
        change(){
            this.$emit('teleconsult', this.telmed)
        }
    }
}
</script>

<style>

</style>