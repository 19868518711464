<template>
     <div
        :id="modalName"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                  <div class="kt-list">
                    <div class="kt-list__item">
                      <label class="col-md-4 btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary">
                        <input type="radio" class="d-none">
                              HH:mm - HH:mm
                      </label>
                      <div class="col-md-8">
                        <span>Jadwal dokter tersedia.</span>
                      </div>
                    </div>

                    <div class="kt-list__item">
                      <label class="col-md-4 btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary active">
                        <input type="radio" class="d-none">
                              HH:mm - HH:mm
                      </label>
                      <div class="col-md-8">
                        <span>Jadwal dokter tersedia dan sedang terpilih.</span>
                      </div>
                    </div>

                    <div class="kt-list__item">
                      <label class="col-md-4 btn btn-elevate btn-pill btn-sm mr-2 btn-metal ">
                        <input type="radio" class="d-none"> 
                              <del>BLOCKED - HH:mm</del>
                      </label>
                      <div class="col-md-8">
                        <span>Jadwal dokter diblok.</span>
                      </div>
                    </div>

                    <div class="kt-list__item">
                      <label class="col-md-4 btn btn-elevate btn-pill btn-sm mr-2 btn-metal ">
                        <input type="radio" class="d-none">
                              HH:mm - HH:mm
                      </label>
                      <div class="col-md-8">
                        <span>Jadwal dokter sudah terisi.</span>
                      </div>
                    </div>

                    <div class="kt-list__item">
                      <label class="col-md-4 btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary">
                        <input type="radio" class="d-none">
                              Waiting List
                      </label>
                      <div class="col-md-8">
                        <span>Jadwal dokter sudah penuh, maka akan didaftarkan sebagai waiting list.</span>
                      </div>
                    </div>


                  </div>
                </div>
              <div class="modal-footer row justify-content-center m-0">
                      <button type="submit" class="btn btn-primary" @click="close()">{{ $t('button.close') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
    };
  },
  props: {
    modalName: { type: String, default:"modalHelp"},
  },
  methods: {
    close() {
        $("#" + this.modalName).modal("hide");
        this.$emit('closeModal');
    },
  },
  mounted() {
    $("#" + this.modalName).modal();
  }
};
</script>
