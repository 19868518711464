var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"model",rawName:"v-model",value:(_vm.reservasi),expression:"reservasi"}],attrs:{"type":"hidden","name":_vm.name,"data-vv-as":_vm.validationName,"data-vv-scope":(_vm.scope != null) ? _vm.scope : null},domProps:{"value":(_vm.reservasi)},on:{"input":function($event){if($event.target.composing)return;_vm.reservasi=$event.target.value}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.first(_vm.errorField)),expression:"errors.first(errorField)"}],staticClass:"kt-font-danger"},[_vm._v(_vm._s(_vm.errors.first(_vm.errorField)))]),_vm._l((_vm.optionsComputed),function(dokter){return _c('div',{key:dokter.id,staticClass:"card mt-2"},[_c('div',{staticClass:"card-header"},[_vm._v(" "+_vm._s(dokter.nama)+" "),(_vm.isTelekonsul && dokter.tarif)?_c('div',{staticClass:"float-right"},[_vm._v(" Rp."+_vm._s(dokter.tarif.jumlah)+" ")]):_vm._e()]),(dokter.holiday)?[_c('div',{staticClass:"card-body"},[_vm._v(" Hari Libur ("+_vm._s(dokter.holidayRemarks)+") ")])]:(!_vm.isTelekonsul || dokter.tarif )?_vm._l((dokter.jadwals),function(jdw){return _c('div',{key:jdw.id,staticClass:"card-body"},[_c('label',{staticClass:"kt-font-bold"},[_vm._v(_vm._s(jdw.jadwal.jamMulai)+" - "+_vm._s(jdw.jadwal.jamSelesai))]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((jdw.slots),function(slot,index){return _c('label',{key:index+dokter.id,staticClass:"btn btn-elevate btn-pill btn-sm mr-2 pointer",class:{
                        'btn-outline-primary' : ['EMPTY', 'WAITING'].includes(slot.type),
                        'btn-metal disabled': ['RESERVED','BLOCKED'].includes(slot.type),
                        'active-disabled': (slot.value.jamMulai ==  _vm.reservasi.jamMulai &&
                                     slot.value.jamSelesai == _vm.reservasi.jamSelesai &&
                                     (_vm.reservasi.jadwal != null && slot.value.jadwal.id == _vm.reservasi.jadwal.id) && ['BLOCKED'].includes(slot.type) ),
                        'active' :  (slot.value.jamMulai ==  _vm.reservasi.jamMulai &&
                                     slot.value.jamSelesai == _vm.reservasi.jamSelesai &&
                                     (_vm.reservasi.jadwal != null && slot.value.jadwal.id == _vm.reservasi.jadwal.id) )
                    }},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reservasi),expression:"reservasi"}],staticClass:"d-none",attrs:{"type":"radio","disabled":['RESERVED','BLOCKED'].includes(slot.type)},domProps:{"value":slot.value,"checked":_vm._q(_vm.reservasi,slot.value)},on:{"change":function($event){_vm.reservasi=slot.value}}}),(slot.type == 'BLOCKED')?_c('del',[_vm._v(" "+_vm._s(slot.value.jamMulai)+" - "+_vm._s(slot.value.jamSelesai))]):_c('div',[_vm._v(" "+_vm._s((slot.type != 'WAITING') ? `${slot.value.jamMulai} - ${slot.value.jamSelesai}` : 'Waiting List')+" ")])])}),0)])}):[_c('div',{staticClass:"card-body text-danger badge-secondary"},[_vm._v(" Dokter tidak melayani telekonsultasi ")])],(dokter.jadwals.length == 0 && !dokter.holiday)?_c('div',{staticClass:"card-body"},[_vm._v(" Dokter tidak memiliki jadwal ")]):_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }