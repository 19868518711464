<template>
    <div class="kt-input-icon kt-input-icon--right">
        <input
            type="email"
            :class="classControl"
            :placeholder="placeholder"
            :name="name"
            v-validate="validationEmail"
            v-model="selectedValue"
            :data-vv-as="validationName"
            :data-vv-scope="scope"
        />
        <span class="kt-input-icon__icon kt-input-icon__icon--right">
            <span>
                <i class="la la-envelope-o"></i>
            </span>
        </span>
        <div v-show="errors.first(errorField)" class="invalid-feedback">{{ errors.first(errorField) }}</div>
    </div>
</template>

<script>
    export default {
        inject: ["$validator"],
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [Array, Number, String, Object],
            validation:{
                type: [String, Object],
                default:''
            },
            validationName:{
                type: String,
                default: 'Email',
            },
            name:{
                type:String,
                default: 'email'
            },
            scope: {
                type: String,
                default:null
            },
            placeholder: {
                type: String,
                default:'Silahkan Masukan Data'
            },
            inputClass:{
                type: String,
                default: 'form-control'
            },
            errorClass:{
                type:String,
                default: 'form-control is-invalid'
            },
            customClass: [String, Array, Function, Object]
        },
        computed: {
            selectedValue: {
                get () {
                    return this.value
                },
                set (newValue) {
                    this.$emit('change', newValue);
                }
            },
            errorField: function(){
                 return (this.scope != null) ? `${this.scope}.${this.name}` : this.name
            },
            classControl: function(){
                if(this.customClass != undefined || this.customClass != null){
                    return this.customClass
                } else {
                    return this.errors.has(this.errorField) ? this.errorClass : this.inputClass;
                }
            },
            validationEmail: function(){
                if(typeof this.validation == 'string'){
                    return `email|${this.validation}`
                } else if( typeof this.validation == 'object'){
                    var valid = {...this.validation};
                    valid.email = true;
                    return valid;
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
