<template>
    <div>
        <input type="hidden"
                :name="name"
                v-validate="'required'"
                v-model="reservasi"
                :data-vv-as="validationName"
                :data-vv-scope="(scope != null) ? scope : null">
        <small class=" kt-font-danger" v-show="errors.first(errorField)">{{errors.first(errorField)}}</small>
        <div class="card mt-2" v-for="dokter in optionsComputed" :key="dokter.id">
            <div class="card-header">
                {{dokter.nama}}
            <div v-if="isTelekonsul && dokter.tarif" class="float-right">
                Rp.{{dokter.tarif.jumlah}}
            </div>
            </div>
            <template v-if="dokter.holiday">
                 <div class="card-body"> Hari Libur ({{dokter.holidayRemarks}}) </div>
            </template>
            <template v-else-if="!isTelekonsul || dokter.tarif ">
            <div class="card-body" v-for="jdw in dokter.jadwals" :key="jdw.id">
                <label class="kt-font-bold">{{jdw.jadwal.jamMulai}} - {{jdw.jadwal.jamSelesai}}</label>
                <div class="d-flex flex-wrap">
                    <label class="btn btn-elevate btn-pill btn-sm mr-2 pointer"
                        :class="{
                            'btn-outline-primary' : ['EMPTY', 'WAITING'].includes(slot.type),
                            'btn-metal disabled': ['RESERVED','BLOCKED'].includes(slot.type),
                            'active-disabled': (slot.value.jamMulai ==  reservasi.jamMulai &&
                                         slot.value.jamSelesai == reservasi.jamSelesai &&
                                         (reservasi.jadwal != null && slot.value.jadwal.id == reservasi.jadwal.id) && ['BLOCKED'].includes(slot.type) ),
                            'active' :  (slot.value.jamMulai ==  reservasi.jamMulai &&
                                         slot.value.jamSelesai == reservasi.jamSelesai &&
                                         (reservasi.jadwal != null && slot.value.jadwal.id == reservasi.jadwal.id) )
                        }"
                        v-for="(slot,index) in jdw.slots" :key="index+dokter.id">
                        <input type="radio" class="d-none"
                            :value="slot.value"
                            v-model="reservasi"
                            :disabled="['RESERVED','BLOCKED'].includes(slot.type)">
                            <del v-if="slot.type == 'BLOCKED'">
                            {{slot.value.jamMulai}} - {{slot.value.jamSelesai}}</del>
                            <div v-else>
                            {{(slot.type != 'WAITING') ? `${slot.value.jamMulai} - ${slot.value.jamSelesai}` : 'Waiting List'}}
                            </div>
                                <!-- {{slot.type}} -->
                    </label>
                </div>
            </div>
            </template>
            <template v-else>
                <div class="card-body text-danger badge-secondary"> Dokter tidak melayani telekonsultasi </div>
            </template>
             <div class="card-body" v-if="dokter.jadwals.length == 0 && !dokter.holiday"> Dokter tidak memiliki jadwal </div>
        </div>
    </div>
</template>

<script>
import ElasticRepository from '../../repositories/ElasticRepository';
    //dummy duration
    const listDuration  = [
        {
            id:10523,
            duration: 10
        },
        {
            id:10521,
            duration: 15
        },
        {
            id:10519,
            duration: 20
        },
        {
            id:10522,
            duration: 30
        },
        {
            id:10520,
            duration: 50
        }
    ]
    const countSlot = (mulai, selesai , durationSlot) => {
        const jamMulai = mulai.split(":");
        const jamSelesai = selesai.split(":");
        const minuteJamMulai =
            parseFloat(jamMulai[0] * 60) + parseFloat(jamMulai[1]);
        const minuteJamSelesai =
            parseFloat(jamSelesai[0] * 60) + parseFloat(jamSelesai[1]);
        const durationMinute = minuteJamSelesai - minuteJamMulai;
        return(Math.floor(durationMinute / durationSlot));
    };
    import SlotJadwalType from "./../../constants/slot-jadwal-type-enum";

    export default {
        inject: ["$validator"],
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: [Array, Number, String, Object, Boolean],
            options: {
                type: Array,
                default: []
            },
            isTelekonsul:{
                type: Boolean,
                default: false
            },
            validation:{
                type: [String, Object],
                default:''
            },
            validationName:{
                type: String,
                default: 'Select Jadwal',
            },
            name:{
                type:String,
                default: 'Select Jadwal'
            },
            scope: {
                type: String,
                default:null
            },
            date:{
                type: String,
            },
            listReservasi:{
                type:Array
            },
            listDefect:{
                type:Array
            },
            listInternalEvent:{
                type:Array
            },
            queueUnitSlot: {
                type:Array
            }
        },
        data() {
            return {
                kumpulan: []
            }
        },
        filters: {
            formatTime: function(value) {
                return moment(value).format('HH:mm');
            }
        },
        computed: {
            optionsComputed(){
                var day =  moment(this.date).locale('en').format('dddd').toUpperCase();
                // let backedDefect = []
                // console.log(this.options)
                let isCuti = false
                return this.options.map(x => {
                    var temp = {...x};
                    // const find = x.jadwals.find(x => x.hari == day);
                    const jam = this.queueUnitSlot
                    temp.jadwals = jam.map(j => {
                        if (j.jadwal && x.id && j.jadwal.dokter.id !== x.id ) return 
                        var tempJadwal = {
                            jadwal: j.jadwal,
                            slots: []
                        }
                        // const foundDuration = listDuration.find(k => k.id == x.id);
                        const durationSlot = (j.jadwal.durationInMinute != null) ? j.jadwal.durationInMinute : 15;
                        // cari jumlah slot
                        const totalSlot = countSlot(j.jadwal.jamMulai, j.jadwal.jamSelesai, durationSlot);
                        let counterSlot = 1;
                        var startMoment = moment(`${this.date} ${j.jadwal.jamMulai}`,'YYYY-MM-DD HH:mm'); 
                        
                        while(counterSlot <= totalSlot){

                            var start = startMoment.clone();
                            var end = startMoment.add(durationSlot, 'm');

                            var objectSlot = {
                                type: SlotJadwalType.EMPTY,
                                value: {
                                    id: this.reservasi.id,
                                    jadwal: j.jadwal,
                                    jamMulai: moment(start).format('HH:mm'),
                                    jamSelesai: moment(end).format('HH:mm'),
                                }
                            }

                            if (j.jadwal.slotIndexBlocked) {
                                let found = j.jadwal.slotIndexBlocked.find(el => el == counterSlot-1);
                                if (found == 0 || found) {
                                    
                                    objectSlot.type = SlotJadwalType.BLOCKED;
                                }
                            }
                            
                            // Cari apakah slot ada reservasi;
                            var haveReservasation = this.listReservasi.find(reservasi => {
                                const slotStart = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamMulai}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
                                const slotSelesai = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamSelesai}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DDTHH:mm:ss');
                                if(j.jadwal.id == reservasi.jadwal.id &&
                                    slotStart == start.format('YYYY-MM-DDTHH:mm:ss') &&
                                    slotSelesai == end.format('YYYY-MM-DDTHH:mm:ss')) return reservasi
                            });

                            if(haveReservasation){
                                objectSlot.type = SlotJadwalType.RESERVED;
                                objectSlot.reservasi = haveReservasation;
                                if(this.reservasi.id != null){
                                    if(this.reservasi.id != haveReservasation.id){
                                        objectSlot.type = SlotJadwalType.BLOCKED;
                                    }
                                } else {
                                // console.log(haveReservasation, 'ini reservasi oi', this.reservasi.id);
                                    objectSlot.type = SlotJadwalType.RESERVED;
                                }
                            } else {
                                var blocked = false;
                                var paramStart = this.listDefect.find(reservasi => {
                                    if ( j.jadwal.id == reservasi.jadwal.id && moment(`${reservasi.tanggalKunjungan} ${reservasi.jamMulai}`).isBetween(start, end) ) return reservasi;
                                });
                                var paramEnd = this.listDefect.find(reservasi => {
                                    if (  j.jadwal.id == reservasi.jadwal.id && moment(`${reservasi.tanggalKunjungan} ${reservasi.jamSelesai}`).isBetween(start, end) ) return reservasi;
                                });
                                 var paramSame = this.listDefect.find(reservasi => {
                                    if (j.jadwal.id == reservasi.jadwal.id && moment(`${reservasi.tanggalKunjungan} ${reservasi.jamMulai}`).isSame(start, end))
                                        return reservasi;
                                });
                                // apakah ada reservasi diantara slot
                                if (paramStart || paramEnd || paramSame) { //  kalau ada diantara slot

                                    let queue = paramStart || paramEnd || paramSame
                                    // let queueId = paramStart ? paramStart.id : paramEnd.id
                                if (paramStart && !paramEnd && queue.id == this.reservasi.id || !paramStart && paramEnd && queue.id == this.reservasi.id ) {
                                    
                                    blocked = false
                                }else{
                                    blocked = true;

                                }
                                    
                                } else {
                                    // check apakah ada irisan
                                    var versionOne = this.listDefect.find(reservasi => {
                                        const reservasiStart = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamMulai}`);
                                        const reservasiEnd = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamSelesai}`);
                                        if ( j.jadwal.id == reservasi.jadwal.id && reservasiStart.isSameOrAfter(start) && reservasiStart.isBefore(end) && reservasiEnd.isAfter(moment(end)) ){
                                            return reservasi
                                        }
                                    });
                                    var versionTwo = this.listDefect.find(reservasi => {
                                        const reservasiStart = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamMulai}`);
                                        const reservasiEnd = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamSelesai}`);
                                        if ( j.jadwal.id == reservasi.jadwal.id && reservasiEnd.isAfter(start) && reservasiEnd.isSameOrBefore(end) && reservasiStart.isBefore(moment(start)) ){
                                            return reservasi
                                        }
                                    });
                                    var versionTree = this.listDefect.find(reservasi => {
                                        const reservasiStart = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamMulai}`);
                                        const reservasiEnd = moment(`${reservasi.tanggalKunjungan} ${reservasi.jamSelesai}`);
                                        if ( j.jadwal.id == reservasi.jadwal.id && reservasiStart.isBefore(start) && reservasiEnd.isAfter(end)){
                                            return reservasi
                                        }
                                    })
                                    blocked =  (versionOne || versionTwo || versionTree);
                                    if(this.listInternalEvent.length > 0) {
                                        
                                      this.listInternalEvent.forEach(el => {
                                        const slotiStart = moment(`${this.date} ${objectSlot.value.jamMulai}`);
                                        const slotEnd = moment(`${this.date} ${objectSlot.value.jamSelesai}`);
                                        const startInternalEvent = moment(`${this.date} ${el.jamMulai}`)
                                        const endtInternalEvent = moment(`${this.date} ${el.jamSelesai}`)
                                        const isInternalEventStart = slotiStart.isBetween(startInternalEvent, endtInternalEvent)
                                        const isInternalEventEnd = slotEnd.isBetween(startInternalEvent, endtInternalEvent)
                                        const isSlotStart = startInternalEvent.isBetween(slotiStart, slotEnd)
                                        const isSlotEnd = endtInternalEvent.isBetween(slotiStart, slotEnd)
                                        if(isInternalEventStart || isInternalEventEnd || isSlotStart || isSlotEnd) {
                                            
                                            blocked = true
                                        }
                                        
                                      });
                                    }
                                }
                                if(blocked){
                                // console.log(objectSlot, 'ini object', this.reservasi);

                                    objectSlot.type = SlotJadwalType.BLOCKED
                                }
                            }
                            // console.log(j.jadwal.statusJadwalDokters , "j.jadwal.statusJadwalDokters");
                            j.jadwal.statusJadwalDokters .forEach(stat => {
                                if((j.jadwal.id == objectSlot.value.jadwal.id) && (stat.tanggal == this.date)){
                                    objectSlot.type = SlotJadwalType.BLOCKED
                                    isCuti = true
                                }
                            })
                            tempJadwal.slots.push(objectSlot);

                            counterSlot++;
                        }
                        // waitingList
                        // var totalBlocked = tempJadwal.slots.filter(m => m.type == SlotJadwalType.BLOCKED || m.type == SlotJadwalType.RESERVED).length;
                        if( !isCuti ){
                            var waitingSlot = {
                                type: SlotJadwalType.WAITING,
                                value: {
                                    id: this.reservasi.id,
                                    jadwal: j.jadwal,
                                    jamMulai: null,
                                    jamSelesai: null,
                                }
                            };
                            tempJadwal.slots.push(waitingSlot);
                        }

                        return tempJadwal;
                    })
                    temp.jadwals = temp.jadwals.filter(Boolean)
                    return temp
                })
                    
                // cari value typenya dapt dr mana
                
            },
            reservasi: {
                get(){
                    return this.value;
                },
                set(newValue){
                   this.$emit('change', newValue);
                }
            },
            errorField: function(){
                 return (this.scope != null) ? `${this.scope}.${this.name}` : this.name
            },
        },
        methods: {

        },
    }
</script>